"use client"

import { InteractiveDescription } from "@deltagreen/react-library"
import { IconHomeBolt } from "@tabler/icons-react"
import { useSetAtom } from "jotai"
import { usePathname } from "next/navigation"

import { NextLink } from "@/components/NextLink"
import { LINKS } from "@/constants/links"
import ExclamationMarkFilledIcon from "@/icons/ExclamationMarkFilledIcon.svg"
import { cn } from "@/utils/tailwind"

import { NavigationItem } from "./menuDefinitions"
import { mobileNavigationOpenAtom } from "./MobileNavigation.utils"

function NavLink(props: { item: NavigationItem }) {
  const { item } = props
  const { href, icon, title, disabled, warning, tooltip } = item

  const pathname = usePathname()
  const isLinkActive = pathname === item.href
  const setIsMenuOpen = useSetAtom(mobileNavigationOpenAtom)

  function renderContent() {
    return (
      <div
        className={cn(
          "flex h-8 items-center justify-between gap-4 rounded-md pl-4 pr-2 transition-colors group-active:bg-gray-50",
          isLinkActive && "bg-gray-50",
          warning && "border-[1.5px] border-amber-500",
        )}
      >
        <div className="flex items-center gap-4">
          <span className={cn(isLinkActive ? "text-gray-600" : "text-gray-400")}>{icon}</span>

          <span className={cn("text-sm text-gray-600", isLinkActive && "font-semibold")}>{title}</span>
        </div>

        {warning && (
          <ExclamationMarkFilledIcon width={20} height={20} color="currentColor" className="text-amber-500" />
        )}
      </div>
    )
  }

  if (disabled) {
    return (
      <InteractiveDescription
        disabled={!tooltip}
        triggerOnlyOnClick
        triggerAsChild
        trigger={<span tabIndex={0}>{renderContent()}</span>}
      >
        <p className="max-w-60 text-xs leading-relaxed text-slate-500 sm:text-sm">{tooltip}</p>
      </InteractiveDescription>
    )
  }

  return (
    <NextLink
      href={href}
      className="group"
      onClick={() => {
        if (isLinkActive) {
          setIsMenuOpen(false)
        }
      }}
    >
      {renderContent()}
    </NextLink>
  )
}

export function MobileNavigationOperator() {
  return (
    <>
      <NavLink
        item={{
          href: LINKS.home,
          icon: <IconHomeBolt size={20} />,
          title: "Přehled zákazníků",
        }}
      />
    </>
  )
}
